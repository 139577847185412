




















































import CFMDialog from '@/scripts/CFMDialog'
import { Component, Prop } from 'vue-property-decorator';
import * as api from '../../scripts/services/api';
import * as blockui from '@/helpers/block-ui'
import * as swal from '@/helpers/swal'
import { Item } from '@syncfusion/ej2-vue-navigations';

@Component<PendingTrainingApproveDialog>({
  components: {
  },
})
export default class PendingTrainingApproveDialog extends CFMDialog {
    @Prop()
    workOrderId?: number;
    //@ts-ignore
    examResultFiles: [] = [];
    certificateFiles: [] = [];
    score = "";
    fileIds= [] = [];

    defaultExamResultFiles: any[] = [];
    defaultCertificateFiles: any[] = [];

    created(): void {
        this.getWorkOrderTrainingFiles();
    }

    async getWorkOrderTrainingFiles() {
        blockui.blockModal(this);
        let response = (await api.workOrderTrainingFileGetAllByWorkOrderId(this.workOrderId)).body.Data;
        response.forEach((item: any) => {
            if (item.TrainingFileType === 2) {
                //@ts-ignore
                this.defaultExamResultFiles.push({
                  ...item.File,
                  ResponseId: item.Id 
              });
            } 
            if (item.TrainingFileType === 1) {
                //@ts-ignore
                this.defaultCertificateFiles.push({
                  ...item.File,
                  ResponseId: item.Id
              });
            }         
            // if (item.TrainingFileType === 2) {
            //     //@ts-ignore
            //     this.defaultExamResultFiles.push(item.File);
            // } else if (item.TrainingFileType === 1) {
            //     //@ts-ignore
            //     this.defaultCertificateFiles.push(item.File);
            // }
        });
        blockui.unBlockModal();
    }

    uid() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }
    downloadBase64Files(file: any) {
        const link = document.createElement("a");
        link.href = `data:${file.ContentType};base64,${file.Data}`;
        link.download = file.Name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    downloadBase64File(file: any) {
    const reader = new FileReader();

    reader.onloadend = () => {
        const base64Data = reader.result as string;
        const link = document.createElement("a");
        link.href = base64Data;
        link.download = file.file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    reader.readAsDataURL(file.file);
}

    handleClickExamResultInput() {
        document.getElementById("examResultInput")?.click()
    }
    async handleFileChange(event: any, type: any) {
    if (event.target.files[0] && (type === 'examResult' || type === 'certificate')) {
        const files = Array.from(event.target.files);
        const fileType = type === 'examResult' ? 2 : type === 'certificate' ? 1 : null;

        if (!fileType) return;

        for (const file of files) {
            const fileData = {
                id: this.uid(),
                file: file,
                type,
            };

            if (type === 'examResult') {
                //@ts-ignore
                this.examResultFiles.push(fileData);
            } else if (type === 'certificate') {
                //@ts-ignore
                this.certificateFiles.push(fileData);
            }

            try {
                //@ts-ignore
                const response = await this.$globalApiClient.file.fileSaveFile({ file: fileData.file, type: fileType });

                if (response?.data?.IsSuccess && response.data.Message) {
                    const fileId = response.data.Message;

                    //@ts-ignore
                    if (!this.fileIds) {
                        //@ts-ignore
                        this.fileIds = {};
                    }
                    //@ts-ignore
                    this.fileIds[fileData.id] = parseInt(fileId);
                } else {
                    const errorMessage = response?.data.Messages?.join(', ') || 'Unknown error occurred';
                    console.error('Failed to save file:', errorMessage);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }
}
    handleChangeExamResultInput(e:any){
        if(e.target.files[0]){
            //@ts-ignore
            this.examResultFiles.push({id: this.uid(), file: e.target.files[0]})
        }
    }
    handleClickexamResultFileDelete(id: any) {
        //@ts-ignore
        this.examResultFiles = this.examResultFiles.filter((item) => item.id !== id);
    }
    handleClickDefaultexamResultFileDelete(id: any) {
        //@ts-ignore
        this.defaultExamResultFiles = this.defaultExamResultFiles.filter((item) => item.Id !== id);
    }

    handleClickCertificateInput() {
        document.getElementById("certificateInput")?.click()
    }
    // handleChangeCertificateInput(e:any){
    //     if(e.target.files[0]){
    //         //@ts-ignore
    //         this.certificateFiles.push({id: this.uid(), file: e.target.files[0]})
    //     }
    // }
    handleClickCertificateFileDelete(id: any) {
        //@ts-ignore
        this.certificateFiles = this.certificateFiles.filter((item) => item.id !== id);
    }
    handleClickDefaultCertificateFileDelete(id: any) {
        //@ts-ignore
        this.defaultCertificateFiles = this.defaultCertificateFiles.filter((item) => item.Id !== id);
    }

    async handleSubmit() {
        blockui.blockModal(this);
        // let examResultFiles: any[] = [];
        // let certificateFiles: any[] = [];
        // await Promise.all(
        //     this.examResultFiles.map(async (item) => {
        //         //@ts-ignore
        //         let response = await this.apiClient.file.fileSaveFile({ file: item.file });
        //         //@ts-ignore
        //         examResultFiles.push({
        //             TrainingFileType: 2,
        //             WorkOrderId: this.workOrderId,
        //             FileId: Number(response.data.Message),
        //         });
        //     })
        // );
        // await Promise.all(
        //     this.certificateFiles.map(async (item) => {
        //         //@ts-ignore
        //         let response = await this.apiClient.file.fileSaveFile({ file: item.file });
        //         //@ts-ignore
        //         certificateFiles.push({
        //             TrainingFileType: 1,
        //             WorkOrderId: this.workOrderId,
        //             FileId: Number(response.data.Message),
        //         });
        //     })
        // );
        // let requestObject = {
        //     WorkOrderId: this.workOrderId,
        //     StatusId: 5,
        //     EvaluationScore: this.score,
        //     WorkOrderTrainingFiles: [...this.examResultFiles, ...this.certificateFiles, ...this.defaultExamResultFiles.map((item) => ({
        //          TrainingFileType: 2, WorkOrderId: this.workOrderId, FileId: item.Id })), 
        //          ...this.defaultCertificateFiles.map((item) => ({ TrainingFileType: 1, WorkOrderId: this.workOrderId, FileId: item.Id }))]
        // }
        const requestObject = {
            WorkOrderId: this.workOrderId,
            StatusId: 5,
            EvaluationScore: this.score,
            WorkOrderTrainingFiles: [
                ...this.examResultFiles.map((item) => ({
                    TrainingFileType: 2,
                    WorkOrderId: this.workOrderId,
                    //@ts-ignore
                    FileId: this.fileIds[item.id] || null,
                })),
                ...this.certificateFiles.map((item) => ({
                    TrainingFileType: 1,
                    WorkOrderId: this.workOrderId,
                    //@ts-ignore
                    FileId: this.fileIds[item.id] || null,
                })),
                ...this.defaultExamResultFiles.map((item) => ({
                    TrainingFileType: 2,
                    WorkOrderId: this.workOrderId,
                    FileId: item.Id,
                })),
                ...this.defaultCertificateFiles.map((item) => ({
                    TrainingFileType: 1,
                    WorkOrderId: this.workOrderId,
                    FileId: item.Id,
                })),
            ].filter((file) => file.FileId),
        };

        let response = await api.approveWorkOrderTraining(requestObject);
        blockui.unBlockModal();
        this.saveSucceeded();
        this.close();
    }
}
