import * as api from '../../scripts/services/api'

// initial state
const state = {
  trainingList: [],
  trainings: [],
  trainingTypeList: [],
  trainingMethodList: [],
  trainingAssessmentList: [],

  assessmentList: [],
  assessments: [],
  userTrainingList: [],
  userTrainingFilter: [],
  userAssessmentList: [],
  userAssessmentFilter: [],
  UserWorkOrderTrainingList: [],
  userDocumentList: [],
  loading: false,
}

// getters
const getters = {}

// actions
const actions = {
  async getTrainingList({ commit }) {
    await api.getTrainings().then((res) => {
      commit('setTrainingList', res.body)
    })
  },
  async getTrainings({ commit }) {
    await api.getTrainingList().then((res) => {
      commit('setTrainings', res.body.Data)
    })
  },
  getTrainingTypeList({ commit }) {
    api.getTrainingTypes().then((res) => {
      commit('setTrainingTypeList', res.body)
    })
  },
  getTrainingMethodList({ commit }) {
    api.getTrainingMethods().then((res) => {
      commit('setTrainingMethodList', res.body)
    })
  },
  getTrainingAssessmentList({ commit }) {
    api.getTrainingAssessments().then((res) => {
      commit('setTrainingAssessmentList', res.body)
    })
  },

  async getAssessmentList({ commit }) {
    await api.getTrainingAssessments().then((res) => {
      commit('setAssessmentList', res.body)
    })
  },
  async getAssessments({ commit }) {
    return await api.getAssessmentList().then((res) => {
      commit('setAssessments', res.body.Data)
    })
  },
  async getUserTrainingList({ commit }, userId) {
    const userTrainingList = (await api.getUserTrainingList({ userId, ...state.userTrainingFilter })).body.Data
    commit('setUserTrainingList', userTrainingList)
  },
  async getUserAssessmentList({ commit }, userId) {
    const userAssessmentList = (await api.getUserAssessmentList({ userId, ...state.userAssessmentFilter })).body.Data
    commit('setUserAssessmentList', userAssessmentList)
  },

  async getUserWorkOrderTrainingList({ commit }, userId) {
    const UserWorkOrderTrainingList = (await api.getUserWorkOrderTrainingList(userId)).body.Data
    commit('setUserWorkOrderTrainingList', UserWorkOrderTrainingList)
  },
  async getUserDocumentList({ commit }, userId) {
    const userDocumentList = (await api.getUserDocumentList(userId)).body
    commit('setUserDocumentList', userDocumentList)
  },
  startLoading({ commit }) {
    commit('setLoading', true);
  },
  stopLoading({ commit }) {
    commit('setLoading', false);
  }
}

// mutations
const mutations = {
  setTrainingList(state, trainings) {
    state.trainingList = trainings
  },
  setTrainings(state, trainings) {
    state.trainings = trainings
  },
  setTrainingTypeList(state, trainingTypes) {
    state.trainingTypeList = trainingTypes
  },
  setTrainingMethodList(state, trainingMethods) {
    state.trainingMethodList = trainingMethods
  },
  setTrainingAssessmentList(state, assessments) {
    state.trainingAssessmentList = assessments
  },
  setUserTrainingList(state, userTrainings) {
    state.userTrainingList = userTrainings
  },
  setUserAssessmentList(state, userAssessments) {
    state.userAssessmentList = userAssessments
  },
  setUserWorkOrderTrainingList(state, UserWorkOrderTrainingList) {
    state.UserWorkOrderTrainingList = UserWorkOrderTrainingList
  },
  setAssessments(state, assessments) {
    state.assessments = assessments
  },
  setAssessmentList(state, assessments) {
    state.assessmentList = assessments
  },
  setUserTrainingFilter(state, userTrainingFilter) {
    state.userTrainingFilter = userTrainingFilter
  },
  setUserAssessmentFilter(state, userAssessmentFilter) {
    state.userAssessmentFilter = userAssessmentFilter
  },
  setUserDocumentList(state, userDocumentList) {
    state.userDocumentList = userDocumentList
  },
  setLoading(state, payload) {
    state.loading = payload;
  },  
}

export default {
  state,
  getters,
  actions,
  mutations,
}
