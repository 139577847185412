<template>
  <div class="card-container">
    <!-- Eğitim Genel Bilgileri -->
    <div class="card">
      <h2 class="card-title">{{ data && data.Name ? data.Name : 'Bulunamadı' }}</h2>
      <div class="card-content">
        <p><strong>Code:</strong> {{ data && data.Code ? data.Code : 'Bulunamadı' }}</p>
        <p><strong>Açıklama:</strong> {{ data && data.Purpose ? data.Purpose : 'Bulunamadı' }}</p>
        <p><strong>Sertifika Var Mı?:</strong> {{ getCertificateStatus(data.IsCertificatedTraining) }}</p>
        <p>
          <strong>Tür:</strong>
          {{ data && data.TrainingType && data.TrainingType.Name ? data.TrainingType.Name : 'Bulunamadı' }}
        </p>
      </div>
      <p>
        <strong>Eğitim Materyalleri:</strong>
        <span v-if="data.TrainingMaterials.length">
          <ul>
            <li v-for="material in data.TrainingMaterials" :key="material.Id">
              <a
                href="#"
                @click.prevent="downloadBase64File(material.File.Base64, material.File.Name, material.File.Type)"
              >
                {{ material.Name }}
              </a>
            </li>
          </ul>
        </span>
        <span v-else>Bulunamadı</span>
      </p>
      <p> 
      <strong>Cihaz:</strong>
      <span v-if="data.TrainingDevices.length">
        <ul class="device-list">
          <li v-for="device in data.TrainingDevices" :key="device.Id">{{ device.Device.Name }}</li>
        </ul>
      </span>
      <span v-else>Bulunamadı</span>
    </p>
      <!-- İçeriği İndir Butonu -->
      <strong>İçerik</strong>
      <div v-html="decodedContent" class="content"></div>
      <p>
        <div class="exam-container">
        <strong style="font-size: 15px;">Eğitim Sınavları:</strong>
        <button class="toggle-button" @click="toggleExams">
          {{ showExams ? 'Gizle' : 'Göster' }}
        </button>

        <transition name="fade">
          <span v-if="showExams" class="exam-list">
            <ul style="list-style-type: disc; margin-left: 20px;" v-if="data.TrainingExams.length">
              <li v-for="exam in data.TrainingExams" :key="exam.Id">
                <a href="#" @click.prevent="downloadBase64File(exam.File.Base64, exam.File.Name, exam.File.Type)">
                   {{ exam.Name }}
                </a>
              </li>
            </ul>
            <span v-else>Bulunamadı</span>
          </span>
        </transition>
         </div>
      </p>
    </div>
    <div class="modal-body">
        <p class="inputHeader" >{{ this.translate('result_document') }}</p>
        <div @click="handleClickExamResultInput" class="file-input-container">
            <label class="file-input-label">Choose file</label>
            <input id="examResultInput" type="file" 
            @change="event => handleFileChange(event, 'examResult')"/>
            <button class="file-input-button">Browse</button>
        </div>
        <div class="selectedFileName" v-for="item in defaultExamResultFiles">
            <span style="color: blue; text-decoration: underline; cursor: pointer; font-size: 14px;" @click="downloadBase64Files(item)" v-text="item.Name"></span>
            <b @click="handleClickDefaultexamResultFileDelete(item.Id)" class="delete-btn">X </b>
        </div>
    </div>
  </div>
</template>

<script>
import * as api from '../../../../scripts/services/api'

export default {
  name: 'training-asignment-users',
  props: {
    workOrderId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      data: null, // Eğitim detaylarını tutacak
      decodedContent: '',
      defaultExamResultFiles: [], 
      examResultFiles: [], 
      examFileId: null,
      showExams: false
    }
  },
   mounted() { 
    this.initializeData()
    this.getWorkOrderTrainingFiles()
  },
  methods: {
    async getWorkOrderTrainingFiles() {
      this.defaultExamResultFiles = [];
      this.examResultFiles = [];
      try{
        let response = (await api.workOrderTrainingFileGetAllByWorkOrderId(this.workOrderId)).body.Data;
        response.forEach((item) => {
            if (item.TrainingFileType === 2) {
                //@ts-ignore
                this.defaultExamResultFiles.push({
                  ...item.File,
                  ResponseId: item.Id // Response Id'yi ekleyerek bağlama yapıyoruz
              });
            } 
        });
      }
      catch (error) {
        console.error('Hata oluştu:', error)
      }

    },
    toggleExams() {
      this.showExams = !this.showExams;
    },
    getCertificateStatus(value) {
      if (value === null) return 'Belirtilmemiş'
      return value ? 'Evet' : 'Hayır'
    },
    decodeHTML(encodedStr) {
      if (!encodedStr) return ''
      return decodeURIComponent(encodedStr)
    },
    async initializeData() {
      try {
        const response = await api.getTrainingListCard(this.workOrderId)
        if (response && response.body && response.body.Data) {
          this.data = response.body.Data
          this.decodedContent = this.decodeHTML(this.data.Content)

          // Dinamik olarak indirme işlemini dinle
          this.setupDownloadListener()
        } else {
          console.error('Beklenen veri bulunamadı:', response)
        }
      } catch (error) {
        console.error('Hata oluştu:', error)
      }    

    },
    setupDownloadListener() {
      this.$nextTick(() => {
        // .download-link sınıfına sahip tüm bağlantıları seç
        const links = document.querySelectorAll('.download-link')
        links.forEach((link) => {
          link.addEventListener('click', (event) => {
            event.preventDefault() // Varsayılan bağlantı davranışını engelle
            const fileId = event.target.getAttribute('data-file-id') // data-file-id değerini al
            if (fileId) {
              this.downloadFile(fileId) // Dosya indirme işlemini başlat
            }
          })
        })
      })
    },
    async downloadFile(fileId) {
      try {
        const response = await api.fileGetFile(fileId)
        if (response.data?.IsSuccess && response.data.Data?.Base64 && response.data.Data?.ContentType) {
          // Dosya indirme işlemi
          const blob = this.base64toBlob(response.data.Data.Base64, response.data.Data.ContentType)
          this.downloadBlob(blob, response.data.Data.Name || `file_${fileId}.pdf`)
        } else {
          console.error('Dosya indirme sırasında beklenmeyen hata:', response?.data?.Message || 'Bilinmeyen hata')
          alert('Dosya indirilemedi.')
        }
      } catch (error) {
        console.error('API çağrısı sırasında hata oluştu:', error)
        alert('Dosya indirme sırasında bir hata oluştu.')
      }
    },
    downloadBase64Files(file) {
        const link = document.createElement("a");
        link.href = `data:${file.ContentType};base64,${file.Data}`;
        link.download = file.Name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },

    downloadBase64File(base64Content, fileName, fileType = 'application/octet-stream') {
      // Base64'ü Blob'a çevir
      const blob = this.base64ToBlob2(base64Content, fileType)
      const url = URL.createObjectURL(blob)

      // İndirme işlemi için bir link oluştur
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.click()

      // Belleği temizle
      URL.revokeObjectURL(url)
    },
    base64ToBlob2(base64, mimeType) {
      // Base64 verisini çöz ve Blob oluştur
      const byteCharacters = atob(base64)
      const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0))
      const byteArray = new Uint8Array(byteNumbers)
      return new Blob([byteArray], { type: mimeType })
    },
    base64toBlob(base64Data, type) {
      try {
        const byteString = atob(base64Data)
        const ab = new ArrayBuffer(byteString.length)
        const ia = new Uint8Array(ab)
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ia], { type: type })
      } catch (error) {
        console.error("Base64 verisi Blob'a dönüştürülemedi:", error)
        throw new Error('Base64 verisi geçersiz.')
      }
    },

    downloadBlob(blob, fileName) {
      const a = document.createElement('a')
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    },

    handleClickExamResultInput() {
        document.getElementById("examResultInput")?.click()
    },

     handleClickexamResultFileDelete(id) {
         //@ts-ignore
         this.examResultFiles = this.examResultFiles.filter((item) => item.id !== id);
     },

    handleClickexamResultFileDelete(id) {
      const responseItem = this.defaultExamResultFiles.find((item) => item.Id === fileId); 
      if (!responseItem) {
          console.error('Silinecek öğe bulunamadı!');
          return;
      }

      const responseId = responseItem.ResponseId; 

      let deleteResponse = api.deleteWorkOrderTrainingDocument(responseId);
          this.defaultExamResultFiles = this.defaultExamResultFiles.filter((item) => item.Id !== fileId);
    },
    handleClickDefaultexamResultFileDelete(fileId) {

      const responseItem = this.defaultExamResultFiles.find((item) => item.Id === fileId); 
      if (!responseItem) {
          console.error('Silinecek öğe bulunamadı!');
          return;
      }

      const responseId = responseItem.ResponseId; 

      let deleteResponse = api.deleteWorkOrderTrainingDocument(responseId);
          this.defaultExamResultFiles = this.defaultExamResultFiles.filter((item) => item.Id !== fileId);
            
      },
    //  handleClickDefaultexamResultFileDelete(id) {
    //      //@ts-ignore
    //      this.defaultExamResultFiles = this.defaultExamResultFiles.filter((item) => item.Id !== id);
    //  },
    async handleFileChange(event, type) {
      if(event.target.files[0]){
             //@ts-ignore
             this.examResultFiles.push({id: this.uid(), file: event.target.files[0]})
        }
      const file = event.target.files[0];
    
      try {
        const response = await this.$globalApiClient.file.fileSaveFile({ file: file, type: 2 });
    
        if (response?.data?.IsSuccess && response.data.Message) {
          this.examFileId = response.data.Message;
          await this.saveFileRecord();

        } else {
          const errorMessage = response?.data.Messages?.join(', ') || 'Unknown error occurred';
          console.error('Failed to save file:', errorMessage);
        }
      } catch (error) {
        console.error('An error occurred during file upload:', error);
      }
    },   
    uid() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    },
    async saveFileRecord() {
    
        let requestObject =  [...this.examResultFiles.map((item) => ({
          TrainingFileType: 2,
          WorkOrderId: this.workOrderId,
          FileId: parseInt( this.examFileId),
        }))];
        let response = await api.saveWorkOrderTrainingFile(requestObject);
        this.getWorkOrderTrainingFiles()
        //this.saveSucceeded();
        this.close();
    }

  },
}
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
  font-family: Arial, sans-serif;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-content p {
  margin: 5px 0;
  font-size: 14px;
}

.btn-download {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.btn-download:hover {
  background-color: #0056b3;
}
.device-list {
  list-style-type: disc; 
  margin-left: 20px; 
}
.exam-container {
  margin-top: 10px;
}

.toggle-button {
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #0088BB;
    color: white;
    border: none;
    border-radius: 4px 4px 4px 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Rubik";
}

.toggle-button:hover {
  background: linear-gradient(to right, #2e648b, #1a088e);
}

.exam-list {
  margin-top: 10px;
  padding: 10px;
  background: #f8f8f8;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.exam-list ul {
  list-style: none;
  padding: 0;
}

.exam-list li {
  margin-bottom: 5px;
}

.exam-list a {
  text-decoration: none;
  color: #007BFF;
  transition: 0.3s;
}

.exam-list a:hover {
  color: #0056b3;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.delete-btn {
  font-weight: 800;
  color: red;
  cursor: pointer;
  font-size: 20px;
  margin-left: 15px;
}



</style>
